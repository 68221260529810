import React, { Component } from "react"
import Swiper from "swiper"
import "swiper/dist/css/swiper.min.css"

export class Gallery extends Component {
  componentDidMount() {
    this.swiper = new Swiper(".swiper-container", {
      ...this.props.options,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    })
  }

  render() {
    return (
      <div className="swiper-container">
        <div className="swiper-wrapper">
          {React.Children.map(this.props.children, (slide) => (
            React.cloneElement(slide, {
              className: `swiper-slide ${slide.props.className || ''}`
            })
          ))}
        </div>

        <div className="swiper-button-next" />
        <div className="swiper-button-prev" />
      </div>
    )
  }
}

export default Gallery

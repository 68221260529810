import React, { useGlobal, useState } from "reactn"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"
import DefaultLayout from "../layouts/DefaultLayout"
import { Modal, Button } from "antd"
import tw from "tailwind.macro"
import Image from "../components/Image"
import Gallery from "../components/Gallery"
import { disableBodyScroll, enableBodyScroll } from "../utils/lockscroll"
import ApartmentPicker from "../components/ApartmentPicker"
import Apartment from "../components/Apartment"
import convert from "../utils/converter"
import FinnLink from "../components/FinnLink"

const Sep = styled.hr`${tw`mx-auto my-8`}`

const PageContainer = styled.div``
const PageMain = styled.main``

const IntroSection = styled.section``
const IntroImg = styled(Image)`
  ${tw`w-full`}
  clip-path: url(#mask)
`
const IntroWrapper = styled.div`
  ${tw`mt-2 mx-auto`}
  @media(max-width: 1200px) {
    margin-top: 30px;
  }
`
const IntroSmall = styled.small`${tw`mb-4`}`
const IntroTitle = styled.h1``
const IntroText = styled.div`${tw`mt-12`}`

const SoveromStueFigure = styled.figure`
  ${tw`my-32`}
  max-width: 85%;
  width: 100%;
  @media(max-width: 900px) {
    max-width: none;
  }
  @media(max-width: 600px) {
    margin-top: 60px;
    margin-bottom: 60px;
  }
`

const SoveromSection = styled.section`${tw`flex flex-col`}`
const SoveromFigure = styled(SoveromStueFigure)`${tw`self-end`}`
const SoveromCaption = styled.figcaption`${tw`my-2`}`

const StandarderSection = styled.section``
const StandarderWrapper = styled.div`${tw`mx-auto`}`
const StandarderTitle = styled.h2`${tw`mb-10`}`
const StandarderText = styled.div``

const StueSection = styled.section`${tw`flex flex-col`}`
const StueFigure = styled(SoveromStueFigure)`${tw`self-start`}`
const StueCaption = styled.figcaption`${tw`mt-2 text-right`}`

const EgenskaperSection = styled.section``
const EgenskaperWrapper = styled.div`${tw`mx-auto flex flex-wrap`}`
const Egenskap = styled.div`
  ${tw`w-1/3 my-6 px-6`}
  @media(max-width: 900px) {
    ${tw`w-1/2`}
  }
  @media(max-width: 600px) {
    ${tw`w-full`}
  }
`
const EgenskapImg = styled(Image)`
  ${tw`w-16 h-16`}
  filter: invert(0.5) sepia(1) saturate(5) hue-rotate(12deg);
`
const EgenskapTitle = styled.h2`${tw`text-3xl my-6`}`
const EgenskapText = styled.p``

const ApartmentsSection = styled.section`
  ${tw`my-32`}
  .swiper-button-next, .swiper-button-prev {
    top: 25%;
  }
  @media(max-width: 600px) {
    margin-top: 60px;
    margin-bottom: 0;
  }
`
const ApartmentGalleryContainer = styled.div`${tw`mt-6`}`
const ApartmentSlide = styled.div`
  max-width: 311px;
  min-width: 311px;
  border: 2px solid transparent;
  padding: 6px;
  border-radius: 6px;
  section:last-child {
    display: none;
  }
  @media(max-width: 400px) {
    max-width: none;
    min-width: 0;
  }
`

const TextImgSection = styled.section`
  ${tw`flex`}
  @media(max-width: 1200px) {
    ${tw`flex-col`}
  }
`
const TextImgContent = styled.div`
  ${tw`flex-1 flex flex-col items-center justify-center`}
`
const TextImgWrapper = styled.div`
  ${tw`py-10`}
  @media(max-width: 1200px) {
    max-width: 1000px;
  }
`
const TextImgSmall = styled.small``
const TextImgTitle = styled.h2`${tw`mt-4 mb-8`}`
const TextImgText = styled.div`${tw`mt-4`}`
const TextImgFigure = styled.figure`${tw`flex-1`}`
const TextImgImg = styled(Image)`
  top: 50%;
  transform: translateY(-50%);
  @media(max-width: 1200px) {
    top: auto;
    transform: none;
  }
`
const KjokkenSection = styled(TextImgSection)``
const BaderomSection = styled(TextImgSection)`
  @media(max-width: 1200px) {
    flex-direction: column-reverse;
  }
`

const OmradetSection = styled.section`
  img {
    width: 100%;
    object-fit: cover;
  }
`
const OmradetWrapper = styled.div`
  ${tw`mx-auto my-32 text-center`}
  @media(max-width: 600px) {
    margin-top: 0;
    margin-bottom: 0;
  }
`
const OmradetTitle = styled.h2``
const OmradetDescription = styled.div``
const OmradetDescriptionWrapper = styled.div`
  ${tw`mx-auto mt-24 mb-32`}
  @media(max-width: 600px) {
    margin-top: 0;
    margin-bottom: 0;
  }
`
const OmradetDescriptionTitle = styled.h2`${tw`mb-10`}`
const OmradetDescriptionText = styled.div``

const KontaktSection = styled.section`
  background-size: cover;
  background-position: center;
  .md-wrapper {
    max-width: 880px;
  }
  ${tw`min-h-screen flex`}
  @media(max-width: 600px) {
    min-height: 0;
  }
`
const KontaktContent = styled.div`
  background-color: rgba(0, 0, 0, 0.66);
  ${tw`flex flex-1 items-center text-center py-16`}
`
const KontaktWrapper = styled.div`${tw`mx-auto`}`
const KontaktTitle = styled.h2``
const KontaktText = styled.div`${tw`mx-auto my-16`}`
const KontaktButtons = styled.div`
  ${tw`flex justify-center items-center`}
  @media(max-width: 600px) {
    flex-direction: column;
    a {
      margin-left: 0 !important;
      margin-top: 1rem;
    }
  }
`

const ActiveApartmentDialog = styled(Modal)`
  img {
    filter: none;
  }
`

const IndexPageTemplate = ({
  introImg,
  introSmall,
  introTitle,
  introText,

  soveromImg,
  soveromImgCaption,

  standarderTitle,
  standarderText,

  stueImg,
  stueImgCaption,

  egenskaper,

  apartmentPickerViewBox,
  apartmentPickerImg,
  apartments,

  kjokkenSmall,
  kjokkenTitle,
  kjokkenText,
  kjokkenImg,

  baderomSmall,
  baderomTitle,
  baderomText,
  baderomImg,

  omradetTitle,
  omradetButton,
  omradetDescriptionTitle,
  omradetDescriptionText,

  kontaktTitle,
  kontaktText,
  kontaktButton,
  kontaktImg
}) => {
  const [ showApartmentDialog, setShowApartmentDialog ] = useState(false)
  const [ selectedApartmentId, setSelectedApartmentId ] = useState(null)
  const [ showMapDialog, setShowMapDialog ] = useState(false)
  const { 1: setSidebarIsOpen } = useGlobal("sidebarIsOpen")

  const closeSelectedApartmentDialog = () => {
    setShowApartmentDialog(false)
    setSelectedApartmentId(null)
    enableBodyScroll()
  }

  const openSelectedApartmentDialog = (id) => {
    setShowApartmentDialog(true)
    setSelectedApartmentId(id)
    disableBodyScroll()
  }

  const closeMapDialog = () => {
    setShowMapDialog(false)
  }

  const openMapDialog = () => {
    setShowMapDialog(true)
  }

  return (
    <PageContainer>
      <PageMain>
        <IntroSection id="om-prosjektet">
          <IntroImg
            src={introImg.src}
            alt={introImg.alt}
            critical={true}
            fadeIn={false}
            placeholderClassName="hehehehe"
          />
          <div className="pad">
            <IntroWrapper className="md-wrapper">
              <IntroSmall className="accent-left">
                {introSmall}
              </IntroSmall>
              <IntroTitle
                className="h1"
                dangerouslySetInnerHTML={{
                  __html: convert(introTitle)
                }}
              />
              <IntroText
                className="sm-wrapper drop-caps"
                dangerouslySetInnerHTML={{
                  __html: convert(introText)
                }}
              />
            </IntroWrapper>
          </div>
        </IntroSection>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "6vw"
          }}
        >
          <FinnLink href="https://www.finn.no/realestate/newbuildings/ad.html?finnkode=149262846" />
        </div>

        <SoveromSection>
          <SoveromFigure>
            <Image
              src={soveromImg.src}
              alt={soveromImg.alt}
            />
            <SoveromCaption className="pad">
              {soveromImgCaption}
            </SoveromCaption>
          </SoveromFigure>
        </SoveromSection>

        <StandarderSection
          id="standarder-og-kvaliteter"
          className="pad"
        >
          <StandarderWrapper className="md-wrapper">
            <StandarderTitle className="h2 accent-left">
              {standarderTitle}
            </StandarderTitle>
            <StandarderText
              className="cc-2 drop-caps"
              dangerouslySetInnerHTML={{
                __html: convert(standarderText)
              }}
            />
          </StandarderWrapper>
        </StandarderSection>

        <StueSection>
          <StueFigure>
            <Image
              src={stueImg.src}
              alt={stueImg.alt}
            />
            <StueCaption className="pad">
              {stueImgCaption}
            </StueCaption>
          </StueFigure>
        </StueSection>

        <EgenskaperSection id="egenskaper">
          <EgenskaperWrapper className="md-wrapper">
            {egenskaper.map(({ img, title, text }, index) => (
              <Egenskap key={index}>
                <EgenskapImg
                  src={img.src}
                  alt={img.alt}
                />
                <EgenskapTitle className="h2">
                  {title}
                </EgenskapTitle>
                <EgenskapText className="p">
                  {text}
                </EgenskapText>
              </Egenskap>
            ))}
          </EgenskaperWrapper>
        </EgenskaperSection>

        <ApartmentsSection id="leilighetene">
          <ApartmentPicker
            title="Velg en bolig i Vækerøveien"
            viewBox={apartmentPickerViewBox}
            backgroundImage={apartmentPickerImg.childImageSharp.fluid.src}
            onSelectApartment={openSelectedApartmentDialog}
          >
            {apartments.map((apartment, index) => (
              <g
                key={index}
                data-id={index}
                data-sold={apartment.sold}
                dangerouslySetInnerHTML={{
                  __html: convert(apartment.pickerOverlay)
                }}
              />
            ))}
          </ApartmentPicker>

          <ApartmentGalleryContainer>
            <Gallery
              options={{
                slidesPerView: "auto",
                roundLengths: true
              }}
            >
              {apartments.map((apartment, index) => (
                <ApartmentSlide key={index}>
                  <Apartment data={apartment} />
                </ApartmentSlide>
              ))}
            </Gallery>
          </ApartmentGalleryContainer>
        </ApartmentsSection>

        <KjokkenSection>
          <TextImgContent>
            <div className="pad">
              <TextImgWrapper className="xs-wrapper">
                <TextImgSmall className="small accent-left">
                  {kjokkenSmall}
                </TextImgSmall>
                <TextImgTitle className="h2">
                  {kjokkenTitle}
                </TextImgTitle>
                <TextImgText
                  dangerouslySetInnerHTML={{
                    __html: convert(kjokkenText)
                  }}
                />
              </TextImgWrapper>
            </div>
          </TextImgContent>
          <TextImgFigure>
            <TextImgImg
              src={kjokkenImg.src}
              alt={kjokkenImg.alt}
            />
          </TextImgFigure>
        </KjokkenSection>

        <BaderomSection>
          <TextImgFigure>
            <TextImgImg
              src={baderomImg.src}
              alt={baderomImg.alt}
            />
          </TextImgFigure>
          <TextImgContent>
            <div className="pad">
              <TextImgWrapper className="xs-wrapper">
                <TextImgSmall className="small accent-left">
                  {baderomSmall}
                </TextImgSmall>
                <TextImgTitle className="h2">
                  {baderomTitle}
                </TextImgTitle>
                <TextImgText
                  dangerouslySetInnerHTML={{
                    __html: convert(baderomText)
                  }}
                />
              </TextImgWrapper>
            </div>
          </TextImgContent>
        </BaderomSection>

        <OmradetSection id="omradet">
          <div className="pad">
            <OmradetWrapper className="md-wrapper">
              <OmradetTitle className="h2">
                {omradetTitle}
              </OmradetTitle>
              <Sep className="sep sep--h" />
              <Button onClick={openMapDialog}>
                {omradetButton}
              </Button>
            </OmradetWrapper>
          </div>

          <Gallery
            options={{
              autoHeight: true
            }}
          >
            <div>
              <img
                src="https://assets.bonum.no/bilder/boliger/holgerslystveien-10/voksenkollen/bonum-bolig-holgerslystveien-10-omrade-villa-1.jpg"
              />
            </div>
            <div>
              <img
                src="https://assets.bonum.no/bilder/boliger/holgerslystveien-10/voksenkollen/bonum-bolig-holgerslystveien-10-omrade-villa-2.jpg"
              />
            </div>
            <div>
              <img
                src="https://assets.bonum.no/bilder/boliger/holgerslystveien-10/voksenkollen/bonum-bolig-holgerslystveien-10-omrade-vannet.jpg"
              />
            </div>
            <div>
              <img
                src="https://assets.bonum.no/bilder/boliger/holgerslystveien-10/voksenkollen/bonum-bolig-holgerslystveien-10-omrade-skog.jpg"
              />
            </div>
            <div>
              <img
                src="https://assets.bonum.no/bilder/boliger/holgerslystveien-10/voksenkollen/bonum-bolig-holgerslystveien-10-omrade-kaja.jpg"
              />
            </div>
            <div>
              <img
                src="https://assets.bonum.no/bilder/boliger/holgerslystveien-10/voksenkollen/bonum-bolig-holgerslystveien-10-omrade-bukta.jpg"
              />
            </div>
            <div>
              <img
                src="https://assets.bonum.no/bilder/boliger/holgerslystveien-10/voksenkollen/bonum-bolig-holgerslystveien-10-omrade-havet.jpg"
              />
            </div>
            <div>
              <img
                src="https://assets.bonum.no/bilder/boliger/holgerslystveien-10/voksenkollen/bonum-bolig-holgerslystveien-10-omrade-fjorden.jpg"
              />
            </div>
            <div>
              <img
                src="https://assets.bonum.no/bilder/boliger/holgerslystveien-10/voksenkollen/bonum-bolig-holgerslystveien-10-omrade-bater.jpg"
              />
            </div>
            <div>
              <img
                src="https://assets.bonum.no/bilder/boliger/holgerslystveien-10/voksenkollen/bonum-bolig-holgerslystveien-10-omrade-kanoer.jpg"
              />
            </div>
            <div>
              <img
                src="https://assets.bonum.no/bilder/boliger/holgerslystveien-10/voksenkollen/bonum-bolig-holgerslystveien-10-omrade-cc-vest.jpg"
              />
            </div>
          </Gallery>

          <OmradetDescription className="pad">
            <OmradetDescriptionWrapper className="md-wrapper">
              <OmradetDescriptionTitle className="h2 accent-left">
                {omradetDescriptionTitle}
              </OmradetDescriptionTitle>
              <OmradetDescriptionText
                className="cc-2 drop-caps"
                dangerouslySetInnerHTML={{
                  __html: convert(omradetDescriptionText)
                }}
              />
            </OmradetDescriptionWrapper>
          </OmradetDescription>
        </OmradetSection>

        <KontaktSection style={{
          backgroundImage: `url("${kontaktImg.childImageSharp.fluid.src}")`
        }}>
          <KontaktContent className="pad">
            <KontaktWrapper className="md-wrapper">
              <KontaktTitle className="h2">
                {kontaktTitle}
              </KontaktTitle>
              <KontaktText
                className="sm-wrapper"
                dangerouslySetInnerHTML={{
                  __html: convert(kontaktText)
                }}
              />
              <KontaktButtons>
                <Button onClick={() => setSidebarIsOpen(true)}>
                  {kontaktButton}
                </Button>

                <a
                  href="/img/vakeroveien-30-salgsoppgave.pdf"
                  target="_blank"
                  style={{
                    marginLeft: "2rem"
                  }}
                >
                  Få salgsoppgaven
                </a>
              </KontaktButtons>
            </KontaktWrapper>
          </KontaktContent>
        </KontaktSection>
      </PageMain>

      <ActiveApartmentDialog
        title={null}
        footer={null}
        visible={showApartmentDialog}
        onOk={closeSelectedApartmentDialog}
        onCancel={closeSelectedApartmentDialog}
      >
        {showApartmentDialog && (
          <Apartment data={apartments[selectedApartmentId]} />
        )}
      </ActiveApartmentDialog>

      <Modal
        title={(
          <h2 className="h2">Vækerøveien på kartet</h2>
        )}
        footer={null}
        visible={showMapDialog}
        onOk={closeMapDialog}
        onCancel={closeMapDialog}
      >
        <div
          style={{
            background: "url('https://i.redd.it/o6m7b0l6h6pz.gif') center center no-repeat"
          }}
        >
          <iframe
            width="100%"
            height="543"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            src="https://maps.google.com/maps?width=100%&height=543&hl=en&coord=59.920423,10.6462358&q=Vækerøveien+30&ie=UTF8&t=&z=13&iwloc=B&output=embed"
          />
        </div>
      </Modal>
    </PageContainer>
  )
}

const imgType = {
  alt: PropTypes.string,
  src: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ])
}

IndexPageTemplate.propTypes = {
  introImg: PropTypes.shape(imgType),
  introSmall: PropTypes.string,
  introTitle: PropTypes.string,
  introText: PropTypes.string,

  soveromImg: PropTypes.shape(imgType),
  soveromImgCaption: PropTypes.string,

  standarderTitle: PropTypes.string,
  standarderText: PropTypes.string,

  stueImg: PropTypes.shape(imgType),
  stueImgCaption: PropTypes.string,

  egenskaper: PropTypes.array,

  apartmentPickerViewBox: PropTypes.string,
  apartmentPickerImg: PropTypes.shape(imgType),
  apartments: PropTypes.array,

  kjokkenSmall: PropTypes.string,
  kjokkenTitle: PropTypes.string,
  kjokkenText: PropTypes.string,
  kjokkenImg: PropTypes.shape(imgType),

  baderomSmall: PropTypes.string,
  baderomTitle: PropTypes.string,
  baderomText: PropTypes.string,
  baderomImg: PropTypes.shape(imgType),

  omradetTitle: PropTypes.string,
  omradetButton: PropTypes.string,
  omradetDescriptionTitle: PropTypes.string,
  omradetDescriptionText: PropTypes.string,

  kontaktTitle: PropTypes.string,
  kontaktText: PropTypes.string,
  kontaktButton: PropTypes.string,
  kontaktImg: PropTypes.shape(imgType)
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <DefaultLayout>
      <IndexPageTemplate
        introImg={frontmatter.introImg}
        introSmall={frontmatter.introSmall}
        introTitle={frontmatter.introTitle}
        introText={frontmatter.introText}

        soveromImg={frontmatter.soveromImg}
        soveromImgCaption={frontmatter.soveromImgCaption}

        standarderTitle={frontmatter.standarderTitle}
        standarderText={frontmatter.standarderText}

        stueImg={frontmatter.stueImg}
        stueImgCaption={frontmatter.stueImgCaption}

        egenskaper={frontmatter.egenskaper}

        apartmentPickerViewBox={frontmatter.apartmentPickerViewBox}
        apartmentPickerImg={frontmatter.apartmentPickerImg}
        apartments={frontmatter.apartments}

        kjokkenSmall={frontmatter.kjokkenSmall}
        kjokkenTitle={frontmatter.kjokkenTitle}
        kjokkenText={frontmatter.kjokkenText}
        kjokkenImg={frontmatter.kjokkenImg}

        baderomSmall={frontmatter.baderomSmall}
        baderomTitle={frontmatter.baderomTitle}
        baderomText={frontmatter.baderomText}
        baderomImg={frontmatter.baderomImg}

        omradetTitle={frontmatter.omradetTitle}
        omradetButton={frontmatter.omradetButton}
        omradetDescriptionTitle={frontmatter.omradetDescriptionTitle}
        omradetDescriptionText={frontmatter.omradetDescriptionText}

        kontaktTitle={frontmatter.kontaktTitle}
        kontaktText={frontmatter.kontaktText}
        kontaktButton={frontmatter.kontaktButton}
        kontaktImg={frontmatter.kontaktImg}
      />
    </DefaultLayout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
}

export default IndexPage

export const indexPageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        introImg {
          alt
          src {
            childImageSharp {
              fluid(maxWidth: 2048 quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        introSmall
        introTitle
        introText

        soveromImg {
          alt
          src {
            childImageSharp {
              fluid(maxWidth: 2048 quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        soveromImgCaption

        standarderTitle
        standarderText

        stueImg {
          alt
          src {
            childImageSharp {
              fluid(maxWidth: 2048 quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        stueImgCaption

        egenskaper {
          img {
            alt
            src {
              childImageSharp {
                fluid(maxWidth: 2048 quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          title
          text
        }

        apartmentPickerViewBox
        apartmentPickerImg {
          childImageSharp {
            fluid(maxWidth: 2048 quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        apartments {
          title
          subtitle
          price
          image {
            alt
            src {
              childImageSharp {
                fluid(maxWidth: 2048 quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          gallery {
            image {
              alt
              src {
                childImageSharp {
                  fluid(maxWidth: 2048 quality: 100) {
                    ...GatsbyImageSharpFluid
                    # publicURL
                  }
                }
              }
            }
          }
          bidUrl
          sold
          colors
          bathrooms
          bedrooms
          entrances
          insideArea
          primaryRoom
          outsideArea
          description
          pickerOverlay
        }

        kjokkenSmall
        kjokkenTitle
        kjokkenText
        kjokkenImg {
          alt
          src {
            childImageSharp {
              fluid(maxWidth: 2048 quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        baderomSmall
        baderomTitle
        baderomText
        baderomImg {
          alt
          src {
            childImageSharp {
              fluid(maxWidth: 2048 quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }

        omradetTitle
        omradetButton
        omradetDescriptionTitle
        omradetDescriptionText

        kontaktTitle
        kontaktText
        kontaktButton
        kontaktImg {
          childImageSharp {
            fluid(maxWidth: 2048 quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

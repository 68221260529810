import React, { Component } from "react"
import styled from "styled-components"
import tw from "tailwind.macro"

const Container = styled.div`
  position: relative;
  margin-bottom: -8px;
`

const Title = styled.h2`
  ${tw`text-3xl text-black pin-t flex items-center content-center text-center`}
  top: 0;
  border: 4px solid #fff;
  text-transform: uppercase;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 1;
  background-color: #fff;
  padding: 10px;
  line-height: 1.25;
  @media(max-width: 900px) {
    ${tw`text-2xl`}
    position: static;
    margin: 0;
    transform: none;
    text-align: center;
    display: block;
  }
  @media(max-width: 600px) {
    ${tw`text-xl`}
  }
`

const Svg = styled.svg`
  background-size: contain;
  * {
    fill: transparent;
    cursor: pointer;
    transition: fill 0.2s;
    stroke: #fff;
    stroke-width: 2px;
  }
`

const A = styled.a`
  &:focus,
  &:hover {
    outline: none;
    * {
      cursor: pointer;
      fill: ${(props) => props.sold ? "rgba(199, 19, 19, 0.35)" : "rgba(19, 199, 58, 0.35)"}
    }
  }
`

export class ApartmentPicker extends Component {
  selectApartment = (id) => {
    this.props.onSelectApartment(id)
  }

  render() {
    return (
      <Container>
        <Title className="h2">
          {this.props.title}
        </Title>

        <Svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox={this.props.viewBox}
          style={{
            backgroundImage: `url('${this.props.backgroundImage}')`
          }}
        >
          {React.Children.map(this.props.children, (apartment) => {
            const id = apartment.props["data-id"]
            const sold = apartment.props["data-sold"] || false

            return (
              <g key={id}>
                <A
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    this.selectApartment(id)
                  }}
                  id={`picker-selector__select-unit__unit-id--${id}__link`}
                  data-id={id}
                  sold={sold}
                >
                  {React.cloneElement(apartment)}
                </A>
              </g>
            )
          })}
        </Svg>
      </Container>
    )
  }
}

export default ApartmentPicker

import React, { Component } from "react"
import styled from "styled-components"
import tw from "tailwind.macro"
import Image from "../components/Image"
import palette from "../design/palette"
import { Modal } from "antd"
import convert from "../utils/converter"

const Title = styled.h2`${tw`h2 block`}`
const Subtitle = styled.small`${tw`small color--accent block`}`
const ApartmentImage = styled(Image)`${tw`mx-auto block rounded my-6`}`

const Price = styled.strong`
  ${tw`text-lg`}
  ${(props) => props.sold ? "text-decoration: line-through; color: #999;" : ""}
`

const BidLink = styled.a`
  border: 2px solid ${palette.lightAccent};
  padding: 2px 12px;
  border-radius: 3px;
  &:hover {
    background-color: ${palette.lightAccent};
    color: ${palette.darkAccent};
  }
  ${(props) => props.sold ? "color: #999 !important; border: none;" : ""}
`

const Section = styled.section`
  ${tw`mt-5 pt-5 border-t border-grey-darkest border-solid flex`}
  &:first-of-type {
    border-top: none;
    padding-top: 0;
  }
`

const SectionTitle = styled.h3`
  ${tw`text-base`}
  width: 90px;
`

const PriceSection = styled(Section)`
  ${tw`mb-6 mt-4 flex justify-between items-center`}
`

const DescriptionSection = styled(Section)`
  ${tw`flex-col items-start`}
`
const DescriptionExcerpt = styled.p`
  ${tw`mb-2`}
`
const DescriptionMore = styled.a`
  ${tw`text-sm`}
`

const Colors = styled.div`
  ${tw`flex flex-wrap items-center`}
  height: 19px;
`
const Color = styled.div`
  ${tw`rounded w-4 h-4 mr-2`}
  background-color: ${(props) => props.color}
`

const Stat = styled.li`
  margin-left: 0;
  font-size: 0.95rem;
  ${tw`flex items-center`}
  &:before {
    content: "";
  }
  &:not(:first-child) {
    ${tw`mt-2`}
  }
`

const StatIcon = styled(Image)`
  width: 25px;
  filter: invert(1);
  ${tw`mr-6`}
`

const GalleryItem = styled.a`
  max-width: 100px;
  width: 100%;
  ${tw`block`}
  &:hover {
    opacity: 0.5;
  }
`

const ApartmentModal = styled(Modal)`
  h3 {
    margin-top: 25px;
  }
  p:not(:first-child) {
    margin-top: 15px;
  }
`

class Apartment extends Component {
  state = {
    showDescriptionDialog: false
  }

  openDescriptionDialog = () => {
    this.setState({ showDescriptionDialog: true })
  }

  closeDescriptionDialog = () => {
    this.setState({ showDescriptionDialog: false })
  }

  descriptionSeeMore = (e) => {
    e.preventDefault()
    this.openDescriptionDialog()
  }

  render() {
    const {
      title,
      subtitle,
      image,
      price,
      sold,
      bidUrl,
      colors,
      gallery,
      bathrooms,
      bedrooms,
      entrances,
      insideArea,
      primaryRoom,
      outsideArea,
      description
    } = this.props.data

    let descriptionExcerpt = description.replace(/<(?:.|\n)*?>/gm, "").substring(0, 100)
    if(description.length > 100) {
      descriptionExcerpt = `${descriptionExcerpt}..`
    }

    return (
      <>
        <div>
          <Title>
            {title}
          </Title>

          {subtitle && (
            <Subtitle>
              <strong>{subtitle}</strong>
            </Subtitle>
          )}

          {/* <ApartmentImage
            src={image.src}
            alt={`${title} - ${subtitle}`}
          /> */}

          <PriceSection>
            <Price sold={sold}>
              {price}
            </Price>

            {bidUrl && (
              <BidLink
                target="_blank"
                disabled={sold}
                sold={sold}
                href={bidUrl}
              >
                <strong>{sold ? "Solgt!" : "Gi bud"}</strong>
              </BidLink>
            )}

            {bidUrl === "" && sold === true && (
              <strong>Solgt!</strong>
            )}
          </PriceSection>

          <DescriptionSection>
            <DescriptionExcerpt className="p">
              {descriptionExcerpt}
            </DescriptionExcerpt>

            <DescriptionMore
              href="#"
              onClick={this.descriptionSeeMore}
            >
              Se mer
            </DescriptionMore>
          </DescriptionSection>

          {colors && (
            <Section>
              <SectionTitle className="h3">
                Farger
              </SectionTitle>
              <Colors>
                {colors.map((color, index) => (
                  <Color key={index} color={color} />
                ))}
              </Colors>
            </Section>
          )}

          <Section>
            <SectionTitle className="h3">
              Antall rom
            </SectionTitle>
            <ul className="-mt-1">
              <Stat>
                <StatIcon
                  src="https://assets.bonum.no/bilder/boliger/holgerslystveien-10/icons/bathtub.svg"
                  alt="Antall baderom"
                />
                {bathrooms} baderom
              </Stat>
              <Stat>
                <StatIcon
                  src="https://assets.bonum.no/bilder/boliger/holgerslystveien-10/icons/bed.svg"
                  alt="Antall soverom"
                />
                {bedrooms} soverom
              </Stat>
              <Stat>
                <StatIcon
                  src="https://assets.bonum.no/bilder/boliger/holgerslystveien-10/icons/door.svg"
                  alt="Antall entre"
                />
                {entrances} entré
              </Stat>
            </ul>
          </Section>

          <Section>
            <SectionTitle className="h3">
              Areal
            </SectionTitle>
            <ul className="-mt-1">
              <Stat>
                <StatIcon
                  src="https://assets.bonum.no/bilder/boliger/holgerslystveien-10/icons/measurment.svg"
                  alt="Størrelse inne"
                />
                {insideArea} m² inneareal
              </Stat>
              <Stat>
                <StatIcon
                  src="https://assets.bonum.no/bilder/boliger/holgerslystveien-10/icons/sofa.svg"
                  alt="Størrelse primær rom"
                />
                {primaryRoom} m² primær rom
              </Stat>
              <Stat>
                <StatIcon
                  src="https://assets.bonum.no/bilder/boliger/holgerslystveien-10/icons/sun.svg"
                  alt="Størrelse ute"
                />
                {outsideArea} m² uteareal
              </Stat>
            </ul>
          </Section>

          {gallery && (
            <Section>
              <SectionTitle className="h3">
                Galleri
              </SectionTitle>
              {gallery.map((galleryItem, index) => (
                <GalleryItem
                  key={index}
                  href={galleryItem.image.src.childImageSharp.fluid.src}
                  target="_blank"
                >
                  <Image
                    src={galleryItem.image.src}
                    alt={galleryItem.image.alt}
                  />
                </GalleryItem>
              ))}
            </Section>
          )}
        </div>

        <ApartmentModal
          title={(
            <h2 className="h2">
              Beskrivelse av {title}
            </h2>
          )}
          footer={null}
          visible={this.state.showDescriptionDialog}
          onOk={this.closeDescriptionDialog}
          onCancel={this.closeDescriptionDialog}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: convert(description)
            }}
          />
        </ApartmentModal>
      </>
    )
  }
}

export default Apartment

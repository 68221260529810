import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

const Image = (props) => {
  let img = null

  if(!!props.src && !!props.src.childImageSharp) {
    img = <Img
      fluid={props.src.childImageSharp.fluid}
      {...props}
    />
  }

  if(props.childImageSharp) {
    img = <Img
      fluid={props.childImageSharp.fluid}
      {...props}
    />
  }

  if(!!props.src && typeof props.src === "string") {
    img = <img {...props} />
  }

  return img
}

Image.propTypes = {
  alt: PropTypes.string,
  childImageSharp: PropTypes.object,
  src: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]).isRequired,
  style: PropTypes.object,
  className: PropTypes.string
}

export default Image

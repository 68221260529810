const preventDefault = (e) => {
  e = e || window.event
  if(e.preventDefault) {
    e.preventDefault()
  }
  e.returnValue = false
}

// const preventDefaultForScrollKeys = (event) => {
//   if(keys[event.keyCode]) {
//     preventDefault(event)
//     return false
//   }
// }

export const disableScroll = () => {
  if(window.addEventListener) {
    window.addEventListener("DOMMouseScroll", preventDefault, false)
  }
  window.onwheel = preventDefault
  window.onmousewheel = document.onmousewheel = preventDefault
  window.ontouchmove = preventDefault
  // document.onkeydown = preventDefaultForScrollKeys
}

export const enableScroll = () => {
  if(window.removeEventListener) {
    window.removeEventListener("DOMMouseScroll", preventDefault, false)
  }
  window.onmousewheel = document.onmousewheel = null
  window.onwheel = null
  window.ontouchmove = null
  document.onkeydown = null
}

export const disableBodyScroll = () => {
  window.document.body.style.overflow = "hidden"
  window.document.body.style.position = "relative"
  window.document.documentElement.style.overflow = "hidden"
  window.document.documentElement.style.position = "relative"
  // window.document.body.addEventListener("touchmove", preventDefault, false)
}

export const enableBodyScroll = () => {
  window.document.body.style.overflow = ""
  window.document.body.style.position = ""
  window.document.documentElement.style.overflow = ""
  window.document.documentElement.style.position = ""
  // window.document.body.removeEventListener("touchmove", preventDefault, false)
}
